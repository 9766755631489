<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/integralExchangeInfo">商品兑换统计管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="兑换的商品名" prop="goodsName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.goodsName"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名称" prop="personName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.personName"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        :loading="downloadLoading"
        @click="downloadXls"
        >导出数据</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column
        prop="personName"
        label="用户名"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="personPhone"
        label="手机号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="goodsName"
        label="兑换的商品名"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="兑换时间"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="exchangeNo"
        label="兑换单号"
        width="180"
      ></el-table-column>
      <el-table-column prop="verificationStatus" label="核销状态" width="150">
        <template slot-scope="{ row }">
          <span v-if="row.verificationStatus == 20">已核销</span>
          <span v-else>未核销</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="verificationNo"
        label="核销单号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="verificationTime"
        label="核销时间"
        width="180"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import integralExchangeInfoApi from "@/api/integral/integralExchangeInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "integralExchangeInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        personName: "",
        goodsName: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      downloadLoading: false,
      loadingText: "",
    };
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex,exportFlag) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
    
      if(exportFlag!=null) {        
        formData.append("exportFlag", 1);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      formData.append("personName", self.queryModel.personName);
      formData.append("goodsName", self.queryModel.goodsName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      integralExchangeInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data

          if(jsonData.result) {
            if(exportFlag){
              //导出
              self.$message({
                showClose: true,
                type: "success",
                message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                dangerouslyUseHTMLString: true,
                duration: 30000
              });
            }
            else{
              //分页查看
              var page = jsonData.data;

              self.tableData = page.data;
              self.totalPages = page.totalPages;
              self.totalElements = page.recordsTotal;
            }
          }
          else{
            self.$message.warning(jsonData.message + "");
          }

        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          integralExchangeInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        integralExchangeInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    downloadXls() {
      this.changePage(1,true);
      //导出
      // var self = this;
      // self.downloadLoading = true;
      // self.loading = true;
      // self.loadingText = "数据导出中，请稍后...";
      // var formData = new FormData();
      // formData.append("pageIndex", self.pageIndex);
      // formData.append("pageSize", self.pageSize);
      // formData.append("personName", self.queryModel.personName);
      // formData.append("goodsName", self.queryModel.goodsName);
      // if (this.field != null) {
      //   formData.append("field", this.field);
      // }
      // if (this.direction != null) {
      //   formData.append("direction", this.direction);
      // }
      // integralExchangeInfoApi.exportXls(formData).then(function(response) {
      //   var jsonData = response.data;
      //   self.downloadLoading = false;
      //   self.loading = false;
      //   if (jsonData.result) {
      //       //下载有错误信息提示的报表
      //       self.$message({
      //         showClose: true,
      //         dangerouslyUseHTMLString: true,
      //         message: `报表已生成，<a href="${jsonData.data}" target="_blank">点击下载报表</a>&nbsp;`,
      //         duration: 60000,
      //         type: 'success'
      //       });
      //   }
      //   else{
      //     self.$message.error(jsonData.message);
      //   }
      // });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>